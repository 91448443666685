import React from 'react';
// @ts-ignore
import Bookkeeping from '../../assets/svg/features/Bookkeeping.inline.svg';
// @ts-ignore
import CorpTax from '../../assets/svg/features/CorporateTax.inline.svg';
// @ts-ignore
import PersTax from '../../assets/svg/features/PersonalTax.inline.svg';
// @ts-ignore
import TechConsult from '../../assets/svg/features/TechnologyConsulting.inline.svg';
// @ts-ignore
import USTax from '../../assets/svg/features/UsPersonalTax.inline.svg';
// @ts-ignore
import BusinessAdvisory from '../../assets/svg/features/BusinessAdvisory.inline.svg';

export const ServicesGrid: React.FC = () => {
  return (
    <div className='features-grid__container mx-auto mx-0 flex flex-col justify-center mb-6'>
      <ul className='features-grid__row'>
        <li className='features-grid'>
          <Bookkeeping />
          <h4>Bookkeeping</h4>
          <ul>
            <li>Cloud accounting setup</li>
            <li>Reconciliations</li>
            <li>Reporting</li>
            <li>Audit proof records</li>
            <li>Payroll</li>
          </ul>
        </li>
        <li className='features-grid'>
          <CorpTax />
          <h4>Corporate Tax</h4>
          <ul>
            <li>Tax planning</li>
            <li>GST/HST and PST</li>
            <li>Financial statements</li>
            <li>Audit support</li>
          </ul>
        </li>
        <li className='features-grid'>
          <TechConsult />
          <h4>Technology Consulting</h4>
          <ul>
            <li>Process optimization</li>
            <li>IT support</li>
            <li>Security</li>
            <li>Networking</li>
          </ul>
        </li>
      </ul>
      <ul className='features-grid__row'>
        <li className='features-grid'>
          <BusinessAdvisory />
          <h4>Business Advisory </h4>
          <ul>
            <li>Business planning</li>
            <li>Budget vs. actual</li>
            <li>KPI reporting</li>
            <li>Business health checkups</li>
            <li>Guidance on government grants</li>
          </ul>
        </li>
        <li className='features-grid'>
          <PersTax />
          <h4>Personal Tax</h4>
          <ul>
            <li>Navigating complex tax situations</li>
            <li>Salary vs. dividends</li>
            <li>Foreign income reporting</li>
            <li>Voluntary disclosures</li>
            <li>Stock options</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
