import React from 'react';
// @ts-ignore
import Bookkeeping from '../../assets/svg/features/Bookkeeping.inline.svg';
// @ts-ignore
import CorpTax from '../../assets/svg/features/CorporateTax.inline.svg';
// @ts-ignore
import PersTax from '../../assets/svg/features/PersonalTax.inline.svg';
// @ts-ignore
import TechConsult from '../../assets/svg/features/TechnologyConsulting.inline.svg';
// @ts-ignore
import USTax from '../../assets/svg/features/UsPersonalTax.inline.svg';
// @ts-ignore
import BusinessAdvisory from '../../assets/svg/features/BusinessAdvisory.inline.svg';
import { ServicesGrid } from './ServicesGrid';

export const Services = () => (
  <div className='home-page entry-content prose max-w-none mt-0 lg:mt-0 lg:mb-4 wp-block-group'>
    <div className='mt-0 get-into-business-section wp-block-group sections__container'>
      <h2 className='text-white'>Accountants providing you financial clarity.</h2>
      <div className='lg:mx-48 mx-10'>
        <p className='text-white font-light'>
          Build a reward winning partnership with Argento CPA that grants you access to:
        </p>
      </div>
    </div>
    <ServicesGrid />
  </div>
);
